import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, Text, Wrapper } from '../../components/Ui';
import { respondTo } from '../../theme/mixin';
import styled from 'styled-components';
function FeiChampionship() {
  const fei_championship = useSelector(state => state.user.user?.fei_championship);
  const {
    target_tcm,
    target_tcm_progressivo,
    target_insurance,
    target_insurance_progressivo,
    target_tcm_rinno,
    target_tcm_rinno_progressivo,
    target_rinno_retail,
    target_rinno_retail_progressivo,
    data_riferimento,
  } = fei_championship || {};

  const data = [
    {
      date: data_riferimento,
      targetLable: 'TARGET TCM',
      target: target_tcm,
      targetProgressivoLable: 'TARGET TCM PROGRESSIVO',
      targetProgressivo: Number((target_tcm_progressivo * 100) / target_tcm).toFixed(2),

      targetProgressivoValue: target_tcm_progressivo,
    },
    {
      date: data_riferimento,
      targetLable: 'TARGET INSURANCE',
      target: target_insurance,
      targetProgressivoLable: 'TARGET INSURANCE PROGRESSIVO',
      targetProgressivo: Number((target_insurance_progressivo * 100) / target_insurance).toFixed(2),
      targetProgressivoValue: target_insurance_progressivo,
    },
    {
      date: data_riferimento,
      targetLable: 'TARGET TCM RINNOVI',
      target: target_tcm_rinno,
      targetProgressivoLable: 'TARGET TCM RINNOVI PROGRESSIVO',
      targetProgressivo: Number((target_tcm_rinno_progressivo * 100) / target_tcm_rinno).toFixed(2),
      targetProgressivoValue: target_tcm_rinno_progressivo,
    },
    {
      date: data_riferimento,
      targetLable: 'TARGET RINNOVI RETAIL',
      target: target_rinno_retail,
      targetProgressivoLable: 'TARGET RINNOVI RETAIL PROGRESSIVO',
      targetProgressivo: Number(
        (target_rinno_retail_progressivo * 100) / target_rinno_retail,
      ).toFixed(2),
      targetProgressivoValue: target_rinno_retail_progressivo,
    },
  ];
  return (
    <Wrapper>
      <Text bold size={21}>
        Data Aggiornamento: {data_riferimento}
      </Text>

      <Container>
        {data.map(item => (
          <Target key={item.fiπrstLable} data={item} />
        ))}
      </Container>
    </Wrapper>
  );
}
const Target = ({ data }) => {
  const {
    date,
    target,
    targetProgressivoLable,
    targetLable,
    targetProgressivo,
    targetProgressivoValue,
  } = data || {};
  return (
    <div className='main-target'>
      <Box>
        <div className='row mb-10'>
          <Text>{targetLable} </Text>
          <Text bold className='price'>
            {target}%
          </Text>
        </div>
        <div className='row'>
          <Text>{targetProgressivoLable} </Text>
          <Text bold className='price'>
            {targetProgressivoValue}%
          </Text>
        </div>
        <div className='progress'>
          <div
            className='track'
            style={{
              width: `${targetProgressivo}%`,

              ...(targetProgressivo <= 50
                ? {
                    backgroundColor: 'red',
                  }
                : targetProgressivo >= 51 && targetProgressivo <= 75
                ? {
                    backgroundColor: 'grey',
                  }
                : targetProgressivo >= 76 && targetProgressivo <= 95
                ? {
                    backgroundColor: 'yellow',
                  }
                : {
                    backgroundColor: 'green',
                  }),
            }}
          ></div>
        </div>
      </Box>
    </div>
  );
};
const Container = styled.div`
  padding: 10px 0 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  .points {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .progress {
    height: 30px;
    margin-top: 20px;
    position: relative;
    background-color: #eee;
    overflow: hidden;

    .track {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: #c1bdbd;
    }
  }
  .row {
    display: flex;
    gap: 10px;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;

    .price {
      white-space: nowrap;
    }
  }
  .main-target {
    width: 100%;
    ${respondTo.sm`
      width: 48%
    `};
  }
`;
export default FeiChampionship;
